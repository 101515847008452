
import { reactive, onMounted, computed, ref } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/LotEnums.ts";
import { Actions as ReportActions } from "@/store/enums/ReportsEnums.ts";
import { useStore } from 'vuex';
import moment from 'moment';
export default {
  name: 'LotShippingHistoryByDate',
  setup(){

    const store = useStore();

    const submitButton = ref<HTMLElement | null>(null);

    const errors = ref(false);

    const formData = reactive({
      startDate : moment(new Date()).format('YYYY-MM-DD'),
      endDate : moment(new Date()).format('YYYY-MM-DD')
    })

    const runReport = () => {

      if(submitButton.value){
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      store.dispatch(ReportActions.LOT_SHIPPING_HISTORY,{
          searchBy : {
          type : 'date',
          value : formData.startDate+', '+formData.endDate
        }
      }).then((response) => {

        errors.value = false;
        submitButton.value?.removeAttribute("data-kt-indicator");

        const blob = new Blob([response], { type: 'application/pdf', filename : 'document.pdf' } as object)

        const url = window.URL.createObjectURL(blob)

        window.open(url)

      }).catch((response) => {

        errors.value = true;

        submitButton.value?.removeAttribute("data-kt-indicator")

      })

    }

    onMounted(() => {

      setCurrentPageBreadcrumbs("Reports",[
        {title : "Lot Shipping History by Date",to : "/reports/lot-shipping-history-by-date"}
      ]);
    });

    return {
      store,
      formData,
      runReport,
      submitButton,
      errors
    }
  }
}
