<template>
  <div>

    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">

      <div class="card-header cursor-pointer">

        <div class="card-title m-0">

          <h3 class="fw-bolder m-0">Lot Shipping History by Date</h3>

        </div>

      </div>
      <div class="card-body p-7">

        <div class="row mb-3" v-if="errors == true">

          <div class="col-12">

            <div class="alert alert-danger" role="alert">
              An error occurred, please try with different dates.
            </div>

          </div>

        </div>

        <div class="row mb-3">

          <div class="col-12 col-lg-6 mb-3 mb-lg-0">

            <label class="col-form-label fw-bold fs-6">Start Date</label>

            <input type="date" class="form-control" :class="{'is-invalid' : errors == true}" v-model="formData.startDate">

          </div>

          <div class="col-12 col-lg-6">

            <label class="col-form-label fw-bold fs-6">End Date</label>

            <input type="date" class="form-control" :class="{'is-invalid' : errors == true}" v-model="formData.endDate">
          </div>

        </div>

        <div class="row">

          <div class="col-12">

            <button
              type="submit"
              ref="submitButton"
              class="btn btn-lg btn-primary fw-bolder ms-auto d-block my-2"
              @click="runReport"
            >
              <span class="indicator-label">
                Run Report
              </span>

              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>

          </div>

        </div>

      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { reactive, onMounted, computed, ref } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/LotEnums.ts";
import { Actions as ReportActions } from "@/store/enums/ReportsEnums.ts";
import { useStore } from 'vuex';
import moment from 'moment';
export default {
  name: 'LotShippingHistoryByDate',
  setup(){

    const store = useStore();

    const submitButton = ref<HTMLElement | null>(null);

    const errors = ref(false);

    const formData = reactive({
      startDate : moment(new Date()).format('YYYY-MM-DD'),
      endDate : moment(new Date()).format('YYYY-MM-DD')
    })

    const runReport = () => {

      if(submitButton.value){
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      store.dispatch(ReportActions.LOT_SHIPPING_HISTORY,{
          searchBy : {
          type : 'date',
          value : formData.startDate+', '+formData.endDate
        }
      }).then((response) => {

        errors.value = false;
        submitButton.value?.removeAttribute("data-kt-indicator");

        const blob = new Blob([response], { type: 'application/pdf', filename : 'document.pdf' } as object)

        const url = window.URL.createObjectURL(blob)

        window.open(url)

      }).catch((response) => {

        errors.value = true;

        submitButton.value?.removeAttribute("data-kt-indicator")

      })

    }

    onMounted(() => {

      setCurrentPageBreadcrumbs("Reports",[
        {title : "Lot Shipping History by Date",to : "/reports/lot-shipping-history-by-date"}
      ]);
    });

    return {
      store,
      formData,
      runReport,
      submitButton,
      errors
    }
  }
}
</script>
